<template>
  <div style="padding: 40px" v-loading="loading">
    <h3>组列表</h3>
    <div style="height: 400px;">
      <el-table :data="group_list.slice( (curr_page - 1) * 5, curr_page * 5 )" style="width: 100%;">
        <el-table-column
            prop="cn"
            label="组名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="gidNumber"
            label="GID"
            width="180">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-pagination
          layout="total, prev, pager, next"
          :total="group_list.length"
          :page-size="5"
          :current-page.sync="curr_page"
      >
      </el-pagination>
    </div>
    <el-divider></el-divider>
    <h3>创建组</h3>
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="组名" style="margin-bottom: 20px;">
          <el-input placeholder="请输入组名" v-model="form.cn"></el-input>
        </el-form-item>

        <el-form-item style="margin-top: 30px;">
          <el-button type="primary" @click="addGroup">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from '../../api';

export default {
  data: () => ({
    loading: false,

    select_group_loading: false,
    select_group_info: null,
    upload_file: false,
    select_group_form: {
      uid: ""
    },
    groupDialogVisible: false,
    form: {
      cn: ""
    },
    group_list: [],
    curr_page: null
  }),
  mounted() {
    this.loading = true;
    api.getGroupList().then(res => {
      if (res.code == 0) {
        this.group_list = res.data;
      } else {
        this.$message.error(res.message);
      }
      this.loading = false;
    }).catch(err => {
      this.$message.error(err.toString());
      console.error(err);
      this.loading = false;
    });
  },
  methods: {
    addGroup() {
      this.loading = true;
      api.addGroup(this.form.cn).then(res => {
        if (res.code == 0) {
          this.group_list.push({
            cn: this.form.cn,
            gidNumber: res.data.gidNumber
          });
          this.form.cn = "";
          this.$message.success("创建成功");
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      }).catch(err => {
        this.$message.error(err.toString());
        console.error(err);
        this.loading = false;
      });
    },
    handleEdit(index, row) {
      console.log(index, row)
      this.$router.push({path: "/manage/group_user", query: {cn: row.cn}});

    },
    addGroupMember() {
      this.select_group_loading = true;
      api.addGroupMember(this.select_group_form.uid, this.select_group_info.cn).then(res => {
        if (res.code == 0) {
          this.select_group_info.memberUid.push({
            uid: this.select_group_form.uid
          });
          this.select_group_form.uid = "";
          this.$message.success("添加用户成功");
        } else {
          this.$message.error(res.message);
        }
        this.select_group_loading = false;
      }).catch(err => {
        this.$message.error(err.toString());
        console.error(err);
        this.loading = false;
      });
    },
    handleDeleteUser(index, row) {
      this.select_group_loading = true;
      api.removeGroupMember(row.uid, this.select_group_info.cn).then(res => {
        if (res.code == 0) {
          let idx = -1;
          for (let i = 0; i < this.select_group_info.memberUid.length; ++i) {
            if (this.select_group_info.memberUid[i].uid == row.uid) {
              idx = i;
              break;
            }
          }
          if (idx >= 0) {
            this.select_group_info.memberUid.splice(idx, 1);
          }
          this.$message.success("删除用户成功");
        } else {
          this.$message.error(res.message);
        }
        this.select_group_loading = false;
      }).catch(err => {
        this.$message.error(err.toString());
        console.error(err);
        this.loading = false;
      });
    }
  }
}
</script>