<template>
  <div>
    <h3>组信息</h3>
    <div v-loading="select_group_loading" style="height: 400px;">

      <div style="font-size: 16px; padding: 0 32px;"
           v-if="select_group_info != null">
        <p>
          <span style="font-size: 18px; color: rgb(96, 98, 102); font-weight: bold;"> 组名：</span>
          <span>{{ select_group_info.cn }}</span>
        </p>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="组成员" name="1">
            <el-table :data="select_group_info.memberUid">
              <el-table-column
                  prop="uid"
                  label="用户名"
                  width="580">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" @click="handleDeleteUser(scope.$index, scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <h3 style="padding: 10px;">添加用户</h3>
            <el-form :inline="true" :model="select_group_form" class="demo-form-inline">
              <el-form-item label="用户名">
                <el-input size="mini" v-model="select_group_form.uid" placeholder="请输入用户名"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button size="mini" type="success" @click="addGroupMember('0')">添加</el-button>
              </el-form-item>
            </el-form>
          </el-collapse-item>
          <el-collapse-item title="批量添加组成员" name="2">
            <el-upload v-if="!preview_show"
                       class="upload-demo"
                       ref="upload"
                       drag
                       :on-remove="handleRemove"
                       accept=".json,.txt"
                       action="alert"
                       :auto-upload="false"
                       :file-list="uploadFiles"
                       :on-change="loadJsonFromFile">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传json, txt文件</div>

            </el-upload>
            <el-table v-else
                      :data="previewData"
                      style="width: 80%; left: 20%">
              <el-table-column
                  prop="user_uid"
                  label="用户名"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="state"
                  label="状态"
                  width="120">
              </el-table-column>
            </el-table>
            <el-row style="padding: 20px;">
              <el-button size="mini" type="success" @click="preview" :disabled="uploadFiles.length == 0"
                         v-show="!preview_show">数据检查
              </el-button>
              <el-button size="mini" type="success" @click="preview_show = !preview_show" v-show="preview_show">关闭检查
              </el-button>
              <el-button size="mini" type="success" :disabled="previewData.length == 0" @click="addGroupMember('1')">
                批量添加
              </el-button>
            </el-row>

          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api';
import router from "@/router";

export default {
  data: () => ({
    loading: false,
    preview_show: false,
    uploadFiles: [],
    previewData: [],
    select_group_loading: false,
    select_group_info: null,
    member_user_uids: [],
    activeName: "1",
    select_group_form: {
      uid: ""
    },
    groupDialogVisible: false,
    form: {
      cn: ""
    },
    group_list: [],
    curr_page: null
  }),
  mounted() {
    let group_cn = this.$route.query.cn;
    this.select_group_info = null;
    this.select_group_form = {uid: ""};
    this.groupDialogVisible = true;
    this.select_group_loading = true;
    api.getGroupInfo(group_cn).then(res => {
      if (res.code == 0) {
        this.select_group_info = {
          cn: res.data.cn,
          gidNumber: res.data.gidNumber,
          memberUid: res.data.memberUid.map(x => ({uid: x}))
        };
        this.member_user_uids = res.data.memberUid;
      } else {
        this.$message.error(res.message);
      }
      this.select_group_loading = false;
    }).catch(err => {
      this.$message.error(err.toString());
      console.error(err);
      this.select_group_loading = false;
    });

  },
  methods: {
    // 仅支持上传单个文件
    loadJsonFromFile(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.uploadFiles = fileList;
      this.previewData = [];
    },
    handleRemove(file, fileList) {
      this.uploadFiles = fileList;
      this.previewData = [];
    },
    preview() {
      // 解析上传的文件

      let _this_ = this;
      _this_.previewData = [];
      let file = _this_.uploadFiles[0]
      if (!(file.name.endsWith(".txt") || file.name.endsWith(".json"))) {
        this.$message.error("目前仅支持提交 json/txt 两种格式的文件！")
      }
      let reader = new FileReader()
      reader.readAsText(file.raw)
      reader.onload = (e) => {
        // 读取文件内容
        const fileString = e.target.result
        // 接下来可对文件内容进行处理
        if (file.name.endsWith(".json")) {
          let data = JSON.parse(fileString);
          if (data.group.cn !== _this_.select_group_info.cn) {
            _this_.message.error("组名不一致")
          } else {
            _this_.previewData = data.group.member_list;
            for (var i = 0; i < _this_.previewData.length; i++) {
              if (_this_.member_user_uids.includes(_this_.previewData[i].user_uid)) {
                _this_.previewData[i]['state'] = "已存在";
                _this_.previewData[i]['state_i'] = "1";
              } else {
                _this_.previewData[i]['state'] = "新增";
                _this_.previewData[i]['state_i'] = "0";
              }
            }
          }
        } else if (file.name.endsWith(".txt")) {
          let file_data = fileString.split("\n");
          for (var j = 0; j < file_data.length; j++) {
            if (file_data[j] === "") {
              continue;
            }
            let data = {"user_uid": file_data[j]}
            if (_this_.member_user_uids.includes(file_data[j])) {
              data['state'] = "已存在";
              data['state_i'] = "1";
            } else {
              data['state'] = "新增";
              data['state_i'] = "0";
            }
            _this_.previewData.push(data)
          }
        }
      }
      _this_.preview_show = true;
    },
    addGroup() {
      this.loading = true;
      api.addGroup(this.form.cn).then(res => {
        if (res.code == 0) {
          this.group_list.push({
            cn: this.form.cn,
            gidNumber: res.data.gidNumber
          });
          this.form.cn = "";
          this.$message.success("创建成功");
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      }).catch(err => {
        this.$message.error(err.toString());
        console.error(err);
        this.loading = false;
      });
    },
    handleEdit(index, row) {
      console.log(index, row)
      router.push("/group_user")


    },

    addGroupMember(type) {
      if (type == '0') {
        this.select_group_loading = true;
        api.addGroupMember(this.select_group_form.uid, this.select_group_info.cn).then(res => {
          if (res.code == 0) {
            this.select_group_info.memberUid.push({
              uid: this.select_group_form.uid
            });
            this.select_group_form.uid = "";
            this.$message.success("添加用户成功");
          } else {
            this.$message.error(res.message);
          }
          this.select_group_loading = false;
        }).catch(err => {
          this.$message.error(err.toString());
          console.error(err);
        });
      } else {
        let uids = [];
        for (let i = 0; i < this.previewData.length; i++) {

          if (this.previewData[i].state_i == "0") {
            uids.push(this.previewData[i].user_uid)
          }

        }
        uids = uids.join(", ");
        this.select_group_loading = true;
        api.addGroupMember(uids, this.select_group_info.cn).then(res => {
          if (res.code == 0) {
            this.select_group_info.memberUid.push({
              uid: this.select_group_form.uid
            });
            this.select_group_form.uid = "";
            this.previewData = [];
            this.uploadFiles = [];
            this.$message.success("添加用户成功");
          } else {
            this.$message.error(res.message);
          }
          this.select_group_loading = false;
        }).catch(err => {
          this.$message.error(err.toString());
          console.error(err);
        });
      }

    },
    handleDeleteUser(index, row) {
      this.select_group_loading = true;
      api.removeGroupMember(row.uid, this.select_group_info.cn).then(res => {
        if (res.code == 0) {
          let idx = -1;
          for (let i = 0; i < this.select_group_info.memberUid.length; ++i) {
            if (this.select_group_info.memberUid[i].uid == row.uid) {
              idx = i;
              break;
            }
          }
          if (idx >= 0) {
            this.select_group_info.memberUid.splice(idx, 1);
          }
          this.$message.success("删除用户成功");
        } else {
          this.$message.error(res.message);
        }
        this.select_group_loading = false;
      }).catch(err => {
        this.$message.error(err.toString());
        console.error(err);
        this.loading = false;
      });
    }
  }
}
</script>